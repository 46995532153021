import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const SuccessPage = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="min-h-screen flex items-center justify-center bg-gradient-to-r from-green-400 to-blue-500 p-4"
    >
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.5 }}
        className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md text-center"
      >
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1.5 }}
          transition={{ duration: 0.5 }}
          className="flex justify-center mb-4"
        >
          <svg
            className="w-16 h-16 text-green-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
          </svg>
        </motion.div>
        <h1 className="text-3xl font-bold mb-4 text-green-600">Booking Confirmed!</h1>
        <p className="text-lg mb-4 text-gray-700">
          Thank you for your booking. Your reservation has been successfully confirmed.Our representative would be soon contacting you with your invoice and provide you assistance throughout your journey!!.
        </p>
        <Link
          to="/"
          className="mt-4 inline-block px-6 py-3 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition duration-300"
        >
          Go to Home
        </Link>
      </motion.div>
    </motion.div>
  );
};

export default SuccessPage;
