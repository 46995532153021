import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Footer from './components/Footer';
import Header from './components/Header';
import Home from './page/Home';
import CategoryPage from './page/CategoryPage';
import { ErrorPage } from './page/ErrorPage';
import SingleHotelView from './page/SingleHotelView';
import GlobleContext from './contextApi/GlobleContex'; 
import BookingPage from './components/booking';
import { AuthProvider } from './contextApi/AuthContext'; 
import VillasList from './components/VillasList';
import TermsAndConditions from './page/TermsAndConditions';
import { Analytics } from "@vercel/analytics/react";
import PrivacyPolicy from "./components/PrivacyPolicy";
import RefundCancellation from "./components/RefundCancellation";
import SuccessPage from './components/SuccessPage';

function App() {
  return (
    <AuthProvider>
      <GlobleContext>
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Category" element={<CategoryPage />} />
            <Route path="/details/:id" element={<SingleHotelView />} />
            <Route path="/SingleHotelView/:id" element={<SingleHotelView />} />
            <Route path="/villas" element={<VillasList />} />
            <Route path="/success" element={<SuccessPage />} />
            <Route path="/booking" element={<BookingPage />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/refund-cancellation" element={<RefundCancellation />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
          <Analytics />
          <Footer />
        </BrowserRouter>
      </GlobleContext>
    </AuthProvider>
  );
}

export default App;
