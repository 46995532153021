// src/components/Header.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import DensityMediumIcon from "@mui/icons-material/DensityMedium";
import { signInWithPopup, GoogleAuthProvider, signOut } from "firebase/auth";
import { useAuth } from '../contextApi/AuthContext';

const Header = () => {
  const [isMenu, setIsMenu] = useState(false);
  const { user, setUser, auth } = useAuth();

  const handleLogin = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => setUser(result.user))
      .catch((error) => console.error("Login Error:", error.message));
  };

  const handleLogout = () => {
    signOut(auth)
      .then(() => setUser(null))
      .catch((error) => console.error("Logout Error:", error.message));
  };

  return (
    <>
      <div className="flex justify-between items-center p-4 pt-8 container m-auto">
        <div className="hidden md:block">
          <ul className="list-none capitalize flex">
            <li className="px-4 relative cursor-pointer">
              <Link to="/">Home</Link>
            </li>
            <li className="px-4 relative cursor-pointer">
              <Link to="/Category">Category</Link>
            </li>
          </ul>
        </div>
        <div className="w-32 md:w-36">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div>
          {user ? (
            <div className="flex items-center">
              <img src={user.photoURL} alt="User" className="rounded-full w-10 h-10 mr-2"/>
              <button className="px-4 p-3 bg-[#d27548] uppercase text-white font-normal rounded" onClick={handleLogout}>
                Logout
              </button>
            </div>
          ) : (
            <button className="px-4 p-3 bg-[#d27548] uppercase text-white font-normal rounded" onClick={handleLogin}>
              Login
            </button>
          )}
        </div>
        <div className="block md:hidden px-8 p-4" onClick={() => setIsMenu(!isMenu)}>
          <DensityMediumIcon />
        </div>
      </div>
      <div className="relative">
        <div className={`${isMenu ? "block" : "hidden"} bg-[#d27548] text-white md:hidden absolute left-0 right-0 mt-2 z-50`}>
          <ul className="list-none capitalize flex flex-col">
            <li className="p-2 text-center cursor-pointer hover:bg-orange-700">
              <Link to="/" onClick={() => setIsMenu(false)}>Home</Link>
            </li>
            <li className="p-2 text-center cursor-pointer hover:bg-orange-700">
              <Link to="/Category" onClick={() => setIsMenu(false)}>Category</Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Header;
