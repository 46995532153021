// src/pages/TermsAndConditions.js
import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Terms and Conditions</h1>
      <p className="mb-4">
        coolstays@365 is a company that provides villas, bungalows, farm houses, home stays, service apartments and/or their individual rooms to guests, mainly for residential purposes to guest, under the brand name “coolstays@365”. The below Terms and Conditions are applicable to all the guests or customers who make bookings any of the Properties (defined below) or their individual rooms through our website, our mobile application, third party websites or third party applications or through any travel agents/third parties/ advertisements/ referrals etc. (collectively “Booking Channels”). All guests should ensure to read and understand all the Terms and Conditions stated on our website before using this website and making a booking through any of the Booking Channels. These Terms and Conditions shall be applicable to guests making a booking through any of the Booking Channels. If you are not agreeable to any of these Terms and Conditions of Use, please do not use this website/Booking Channels and do not make a Booking for any of the Properties. These Terms and Conditions are subject to change and CoolStays may at any time, without prior notice to you/ any guest, revise them and any other information contained in this website and/or any Booking Channel by updating these Terms and Conditions. Please note that the restated Terms and Conditions shall be effective immediately upon posting. CoolStays may also make improvements or changes in the services provided by it as described in this website/ Booking Channels at any time without notice. Therefore, you are advised to regularly check for any amendments or updates to these Terms and Conditions. If the changes are not agreeable to you, you must stop using the website. Your continuous use of this website will imply your acceptance of the changed terms.
      </p>
      <p className="mb-4">
        Except where additional terms and conditions are provided, these Terms and Conditions supersede all previous representations, understandings or agreements and shall prevail notwithstanding any variance with any other terms of any order placed or service provided by CoolStays.
      </p>
      <p className="mb-4">
        For the purpose of online as well as offline bookings made through our platform, the terms used in these Terms and Conditions have the following meaning:
        <ul className="list-disc pl-6">
          <li>
            (a) “Property/Properties” shall mean and include villas, bungalows, farm houses & home stays, service apartments and/or their individual rooms available for booking on any of the Booking Channels.
          </li>
          <li>
            (b) “Guest/Guests” refers to any individuals/ companies or other entities who express interest to make a booking in respect of any of the Properties through any Booking Channels. Guests may be referred to as you, your in given context.
          </li>
          <li>
            (c) “Booking” means a reservation made by any Guest in respect of any of the Properties for the purpose of stay.
          </li>
          <li>
            (d) "Check-In" is the scheduled date and time on or before which the Guests shall be liable to comply with all the check-in formalities as required by coolstay365 and after completion of such formalities the Guest shall be entitled to start using the concerned property.
          </li>
          <li>
            (e) "Check-Out” is the scheduled date and time on which the Guest will have to vacate the concerned Property after completion of all the check-out formalities as required by coolstay365.
          </li>
          <li>
            (f) “Booking Period” is the period between which the scheduled Check-In starts and the scheduled Check-Out ends.
          </li>
          <li>
            (g) "Booking Amount” shall mean the total amount payable by the Guest to CoolStays for confirmation of the Booking through the registered mode of payment, as may be intimated by CoolStays to the Guest.
          </li>
          <li>
            (h) “Booking Confirmation” shall mean the email/communication shared by CoolStays with the Guests upon receipt of the Advance (defined below) and shall comprise of all the details of the Booking, Terms & Conditions governing the Booking, name and total number of Guests, Check-In and Check-Out details, name of Property, number of rooms at the Property, the Booking Amount payable and Security Deposit amount payable and specified due dates by which they are required to be paid/deposited. CoolStays shall not be liable to serve a Booking unless the entire Booking Amount is paid by the Guest/Guests to CoolStays through the registered mode of payment intimated by CoolStays to the Guest as stated herein, even though the Booking Confirmation may have been sent to the Guest. The Booking Confirmation along with proof of payment of the entire Booking Amount shall be required to be produced by Guests at the Property at the time of Check-In.
          </li>
          <li>
            (i) "Advance" is the non-refundable amount required to be deposited by Guests with CoolStays as deposit for putting a Booking on Hold (as stated hereinbelow) in respect of a Property.
          </li>
          <li>
            (j) "Security Deposit" is the amount required to be deposited by Guests on a case to case basis with CoolStays. The Security Deposit shall be utilized by CoolStays for any damages caused by the Guests/their pets to the Property and/or items/furniture therein and/or for recovery of any other charges levied by CoolStays on Guests.Mandatory Security deposit is 5000 INR
          </li>
          <li>
            (k) “Peak Days” includes long weekends, festive dates including but not limited to Diwali, New Years, Christmas etc.
          </li>
        </ul>
      </p>
    </div>
  );
};

export default TermsAndConditions;
