import React, { useState } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

export function CardSlider() {
  return (
    <div className='container m-auto mb-12'>
      <Card />
    </div>
  );
}

export const Card = ({ detail }) => {
  const [hoverEffect, setHoverEffect] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const style = {
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical',
  };

  return (
    <div className="relative h-[400px] w-[300px] rounded-md mb-7">
      <img
        src={detail.images && detail.images.length > 0 ? detail.images[0] : 'default-image.jpg'}
        alt={detail.name}
        className="z-0 h-full w-full rounded-md object-cover"
        onClick={() => setIsOpen(true)}
      />
      <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-transparent"></div>
      <div className="absolute bottom-4 left-4 text-left">
        <h1 className="text-lg font-semibold text-white">{detail.name}</h1>
        <p
          className="mt-2 text-sm text-gray-300 overflow-hidden text-ellipsis ease-linear"
          style={hoverEffect ? null : style}
          onMouseEnter={() => setHoverEffect(true)}
          onMouseLeave={() => setHoverEffect(false)}
        >
          {detail.description}
        </p>
        <button className="mt-2 inline-flex cursor-pointer capitalize items-center text-sm font-semibold text-white border rounded px-3 py-1.5 hover:bg-[#fcf2de] ease-linear hover:text-[#633a29]">
          {detail.type} &rarr;
        </button>
      </div>
      {isOpen && (
        <Lightbox
          open={isOpen}
          close={() => setIsOpen(false)}
          slides={detail.images.map((src) => ({ src }))}
        />
      )}
    </div>
  );
};
