import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import brand from "../assets/pixelpioneerio-high-resolution-logo-transparent.png";

const Footer = () => {
  return (
    <section className="relative overflow-hidden py-10">
      <div className="relative z-10 mx-auto max-w-7xl px-4">
        <div className="-m-6 flex flex-wrap">
          <div className="w-full p-6 md:w-1/2 lg:w-4/12">
            <div className="flex h-full flex-col justify-between">
              <div className="mb-4 inline-flex items-center w-20 md:w-30">
                <img
                  src={logo}
                  alt="logo"
                  style={{
                    width: '75px',
                    height: 'auto',
                    transition: 'transform 0.3s ease-in-out',
                  }}
                  onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
                  onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                />
                <span className="ml-4 text-lg font-bold">Coolstays@365</span>
              </div>
              <div className="mb-1">
                FIND US:Andheri(W),
                 <p>Flat No- 602, NS Mankikar Rd, Gulmohar Road, JVPD Scheme, Juhu, Mumbai, Maharashtra 400049</p>
              </div>
            </div>
          </div>
          <div className="w-full p-6 md:w-1/2 lg:w-2/12">
            <div className="h-full">
              <h3 className="tracking-px mb-9 text-xs font-semibold uppercase">
                Web Developer
              </h3>
              <img
                src={brand}
                alt="Developer Logo"
                style={{
                  width: '190px',
                  height: 'auto',
                  transition: 'transform 0.3s ease-in-out',
                }}
                className="object-cover"
                onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
                onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}
              />
              <p className="text-sm mt-3">
                Omkar Komarpant<br/>
                <a href="mailto:workkomarpant@gmail.com">workkomarpant@gmail.com</a>
              </p>
            </div>
          </div>
          <div className="w-full p-6 md:w-1/2 lg:w-2/12">
            <div className="h-full">
              <h3 className="tracking-px mb-9 text-xs font-semibold uppercase">
                Company
              </h3>
              <ul>
                <li className="mb-4">
                  <a href="https://www.instagram.com/coolstays365/" target="_blank" rel="noopener noreferrer">
                    Ig Account
                  </a>
                </li>
                <li className="mb-4">
                  +91 9819877770
                </li>
                <li className="mb-4">
                  +91 7909530053
                </li>
                <li className="mb-4">
                  <a href="mailto:coolstays365@gmail.com?subject=Help%20Needed&body=Please%20describe%20your%20issue:">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="w-full p-6 md:w-1/2 lg:w-4/12">
            <div className="h-full">
              <h3 className="tracking-px mb-9 text-xs font-semibold uppercase">
                Legals
              </h3>
              <ul>
                <li className="mb-4">
                  <Link to="/terms-and-conditions">Terms &amp; Conditions</Link>
                </li>
                <li className="mb-4">
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/refund-cancellation">Refund/Cancellation</Link>
                </li>
                <li>
                <p className="text-sm">
                  &copy; Copyright 2024. All Rights Reserved by Coolstays@365.
                </p>
                </li>
              </ul>
            </div>
            
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
