import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBEgIqy8YAbry50UKNznQ9EAqT3AXxsHGM",
  authDomain: "client-website-89358.firebaseapp.com",
  projectId: "client-website-89358",
  storageBucket: "client-website-89358.appspot.com",
  messagingSenderId: "301746917710",
  appId: "1:301746917710:web:81dfc1200dcae59cf08e1f"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

// Initialize Firebase Storage
const storage = getStorage(app);

// Initialize Firebase Auth
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { db, storage, auth, provider }
