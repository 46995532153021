import React, { useContext } from 'react';
import { Box, InputLabel, MenuItem, FormControl, Select } from '@mui/material';
import Contex from '../contextApi/Contex';

const SearchRooms = () => {
  return (
    <div className="p-2">
      <div className="m-4 mx-auto p-4 px-20 rounded-xl bg-[#fcf2dd] w-fit flex flex-col md:flex-row justify-center gap-10 items-center">
        <div>
          <span>Packages</span>
          <h3 className="text-4xl">Treat Yourself</h3>
        </div>
        <RoomComponents Roomtitle={'Room'} RoomOption={['All','1bhk','2bhk','3bhk', '4bhk', '5bhk', '8bhk', '14bhk']}/>
      </div>
    </div>
  );
};

export default SearchRooms;


const RoomComponents = ({ Roomtitle, RoomOption }) => {
  const { Room, setRoom } = useContext(Contex);
  const handleChange = (event) => setRoom(event.target.value);

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel>{Roomtitle}</InputLabel>
        <Select value={Room} label="Room" onChange={handleChange}>
          {RoomOption.map((item, index) => <MenuItem key={index} value={item}>{item}</MenuItem>)}
        </Select>
      </FormControl>
    </Box>
  );
};
