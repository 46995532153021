import React from "react";

const RefundCancellation = () => {
  return (
    <section className="py-10 px-4">
      <div className="mx-auto max-w-7xl">
        <h1 className="text-2xl font-bold mb-6">Refund and Cancellation Policy</h1>
        <p className="mb-4">
          Thank you for choosing Coolstays@365 for your vacation rental needs. We strive to provide excellent service to our guests, and our Refund and Cancellation Policy is designed to ensure a fair and transparent process for handling refunds and cancellations. Please read this policy carefully before making a reservation.
        </p>
        
        <h2 className="text-xl font-semibold mb-4">Reservation Cancellation by Guest</h2>
        <p className="mb-4">
          If you wish to cancel a reservation made on Coolstays@365, the cancellation policy of the specific property you have booked will apply. The cancellation policy may vary for each property and will be clearly stated during the reservation process. Please review the cancellation policy of the property before making a reservation.
        </p>

        <h2 className="text-xl font-semibold mb-4">Refund Policy</h2>
        <p className="mb-4">
          Refunds, if applicable, will be processed according to the cancellation policy of the specific property and will be subject to the following conditions:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li><strong>Full Refund:</strong> If the reservation is cancelled within the free cancellation period stated in the property's cancellation policy, a full refund will be issued to the guest after a deduction of 5% Convenience Charges.</li>
          <li><strong>Partial Refund:</strong> If the reservation is cancelled after the free cancellation period but before the check-in date, a partial refund may be issued to the guest, based on the property's cancellation policy. The amount of refund will be determined by the property and may be subject to deductions for any applicable fees or charges.</li>
          <li><strong>No Refund:</strong> If the reservation is cancelled after the check-in date or in case of a no-show, no refund will be issued to the guest.</li>
        </ul>

        <h2 className="text-xl font-semibold mb-4">Reservation Cancellation by Coolstays@365</h2>
        <p className="mb-4">
          Coolstays@365 reserves the right to cancel a reservation in the following circumstances:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li><strong>Force Majeure:</strong> In case of unforeseen circumstances beyond our control, such as natural disasters, acts of God, government regulations, or any other event that makes it impossible or unsafe to fulfill the reservation, Coolstays@365 may cancel the reservation and issue a full refund to the guest.</li>
          <li><strong>Property Unavailability:</strong> If the reserved property becomes unavailable due to unforeseen circumstances, such as property damage, maintenance issues, or any other reason, Coolstays@365 may cancel the reservation and provide alternative accommodation of equal or higher value, or issue a full refund to the guest.</li>
        </ul>

        <h2 className="text-xl font-semibold mb-4">Reservation Modifications</h2>
        <p className="mb-4">
          If you need to modify your reservation, such as changing the dates of your stay, please contact Coolstays@365 as soon as possible. Reservation modifications are subject to availability and the property's cancellation policy. Any changes to the reservation may result in additional charges or fees, as determined by the property.
        </p>

        <h2 className="text-xl font-semibold mb-4">Refund Processing</h2>
        <p className="mb-4">
          Refunds, if applicable, will be processed by Coolstays@365 within 3-4 business days from the date of cancellation. The refund will be issued to the same method of payment used for the original reservation unless otherwise specified.
        </p>

        <h2 className="text-xl font-semibold mb-4">Contact Us</h2>
        <p className="mb-4">
          If you have any questions or concerns about our Refunds & Cancellation Policy, please contact us at <a href="mailto:coolstays365@gmail.com">coolstays365@gmail.com</a>.
        </p>

        <p className="mb-4">
          By making a reservation on Coolstays@365, you acknowledge that you have read, understood, and agreed to our Refunds & Cancellation Policy. Thank you for choosing Coolstays@365 for your vacation.
        </p>
      </div>
    </section>
  );
};

export default RefundCancellation;
