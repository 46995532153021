import React from 'react';
import { useNavigate } from 'react-router-dom';
import Showcase from './Showcase';
import { HotelDetail } from '../Detail/HotelDetail';
import WrapperContainer from './WrapperContainer';

const PackageDetail = () => {
  const navigate = useNavigate();

  const handleShowcaseClick = (id) => {
    navigate(`/details/${id}`);
  };

  const handleCategoryClick = () => {
    navigate('/Category');
  };

  return (
    <div className="bg-[#0b8185] min-h-screen">
      <WrapperContainer>
        <div className="flex flex-col md:flex-row items-center p-4 py-12 text-[#fcf2de]">
          <div className="w-full md:w-1/2">
            <span className="block text-lg">Packages</span>
            <h3 className="text-4xl font-bold mt-2">Treat Yourself</h3>
          </div>
          <div className="w-full md:w-1/2 mt-4 md:mt-0">
            <p className="text-[16px]">
              Pick from our specially curated packages or make your own. We serve you as per your requirement. Connect with us on our Whatsapp or Email.
            </p>
          </div>
        </div>

        <div
          id="showcase-Section"
          className="container m-auto flex flex-wrap flex-col md:flex-row justify-between items-center"
        >
          {HotelDetail.map((detail) => (
            <Showcase
              key={detail.id}
              id={detail.id}
              img={detail.image}
              title={detail.title}
              desc={detail.desc}
              button={detail.button}
              onClick={handleShowcaseClick}
            />
          ))}
        </div>

        <div className="flex justify-center my-8">
          <div
            className="group relative overflow-hidden m-2 p-4 bg-[#fcf2de] text-[#0b8185] rounded-lg shadow-md cursor-pointer flex items-center transition-transform transform hover:scale-105 animate-bounce"
            onClick={handleCategoryClick}
          >
            <span className="text-lg font-semibold">...and many more</span>
            <div className="absolute inset-0 bg-[#0b8185] opacity-0 group-hover:opacity-80 transition-opacity duration-500"></div>
            <span className="absolute inset-0 flex justify-center items-center opacity-0 group-hover:opacity-100 text-[#fcf2de] transition-opacity duration-500">Discover More</span>
          </div>
        </div>
      </WrapperContainer>
    </div>
  );
};

export default PackageDetail;
