import image1 from '../assets/p1.webp'
import image2 from '../assets/p2.webp'
import image3 from '../assets/p3.webp'
import image4 from '../assets/p4.webp'
import image5 from '../assets/p5.webp'
import image6 from '../assets/p6.webp'
import image7 from '../assets/p7.webp'
import image8 from '../assets/p8.webp'
import image9 from '../assets/p9.webp'

import image12 from '../assets/q6.webp'
import image11 from '../assets/q5.webp'
import image13 from '../assets/q7.webp'
import image15 from '../assets/q9.webp'
import image16 from '../assets/q11.webp'
import image17 from '../assets/q14.webp'
import image18 from '../assets/q15.webp'
import image19 from '../assets/q16.webp'

import image23 from '../assets/q19.webp'

import image31 from '../assets/a2.webp'
import image32 from'../assets/a1.webp'
import image33 from '../assets/a3.webp'
import image34 from '../assets/a5.webp'
import image35 from '../assets/a4.webp'
import image36 from '../assets/a6.webp'
import image37 from '../assets/a7.webp'
import image38 from '../assets/a8.webp'
import image39 from '../assets/a9.webp'
import image40 from '../assets/a10.webp'
import image41 from '../assets/a11.webp'
import image42 from '../assets/a12.webp'

import image51 from '../assets/b1.webp'
import image52 from'../assets/b2.webp'
import image53 from '../assets/b3.webp'
import image54 from '../assets/b5.webp'
import image55 from '../assets/b4.webp'
import image56 from '../assets/b6.webp'
import image57 from '../assets/b7.webp'
import image58 from '../assets/b8.webp'
import image59 from '../assets/b9.webp'
import image60 from '../assets/b10.webp'
import image61 from '../assets/b11.webp'
import image62 from '../assets/b12.webp'
import image63 from '../assets/b13.webp'
import image64 from '../assets/b14.webp'
import image65 from '../assets/b15.webp'

import image71 from '../assets/71.webp'
import image72 from'../assets/73.webp'
import image74 from '../assets/75.webp'
import image75 from '../assets/74.webp'
import image76 from '../assets/76.webp'
import image77 from '../assets/77.webp'
import image78 from '../assets/78.webp'
import image79 from '../assets/79.webp'
import image80 from '../assets/80.webp'
import image81 from '../assets/81.webp'
import image82 from '../assets/82.webp'
import image83 from '../assets/83.webp'
import image84 from '../assets/84.webp'
import image85 from '../assets/85.webp'
import image86 from '../assets/86.webp'
import image87 from '../assets/88.webp'
import image89 from '../assets/89.webp'
import image90 from '../assets/90.webp'
import image91 from '../assets/91.webp'

import image93 from'../assets/93.webp'
import image94 from '../assets/95.webp'
import image95 from '../assets/94.webp'
import image96 from '../assets/96.webp'
import image97 from '../assets/97.webp'
import image98 from '../assets/98.webp'
import image99 from '../assets/99.webp'
import image100 from '../assets/100.webp'
import image101 from '../assets/101.webp'
import image102 from '../assets/102.webp'
import image103 from '../assets/103.webp'
import image104 from '../assets/104.webp'
import image105 from '../assets/105.webp'

import image107 from '../assets/107.webp'
import image109 from '../assets/109.webp'
import image110 from '../assets/110.webp'
import image111 from '../assets/111.webp'
import image112 from '../assets/112.webp'
import image113 from '../assets/113.webp'
import image115 from '../assets/115.webp'
import image116 from '../assets/116.webp'
import image117 from '../assets/117.webp'
import image118 from '../assets/118.webp'
import image119 from '../assets/119.webp'
import image120 from '../assets/120.webp'
import image121 from '../assets/121.webp'
import image122 from '../assets/122.webp'
import image123 from '../assets/123.webp'
import image124 from '../assets/124.webp'

import image125 from '../assets/125.webp'
import image127 from '../assets/127.webp'
import image128 from '../assets/128.webp'
import image129 from '../assets/129.webp'
import image130 from '../assets/130.webp'
import image131 from '../assets/131.webp'
import image132 from '../assets/132.webp'
import image133 from '../assets/133.webp'
import image134 from '../assets/134.webp'
import image135 from '../assets/135.webp'
import image136 from '../assets/136.webp'
import image137 from '../assets/137.webp'
import image138 from '../assets/138.webp'
import image139 from '../assets/139.webp'
import image140 from '../assets/140.webp'
import image141 from '../assets/141.webp'
import image142 from '../assets/142.webp'
import image143 from '../assets/143.webp'
import image144 from '../assets/144.webp'
import image145 from '../assets/145.webp'


import image146 from '../assets/146.webp'
import image147 from '../assets/147.webp'
import image148 from '../assets/148.webp'
import image149 from '../assets/149.webp'
import image150 from '../assets/150.webp'
import image151 from '../assets/151.webp'
import image152 from '../assets/152.webp'
import image153 from '../assets/153.webp'
import image155 from '../assets/155.webp'
import image156 from '../assets/156.webp'
import image157 from '../assets/157.webp'
import image159 from '../assets/159.webp'
import image160 from '../assets/160.webp'

import image161 from '../assets/161.webp'
import image162 from '../assets/162.webp'
import image163 from '../assets/163.webp'
import image164 from '../assets/164.webp'
import image165 from '../assets/165.webp'
import image166 from '../assets/166.webp'
import image167 from '../assets/167.webp'
import image168 from '../assets/168.webp'
import image169 from '../assets/169.webp'
import image170 from '../assets/170.webp'
import image171 from '../assets/171.webp'
import image172 from '../assets/172.webp'

import image173 from '../assets/173.webp'
import image174 from '../assets/174.webp'
import image175 from '../assets/175.webp'
import image176 from '../assets/176.webp'
import image177 from '../assets/177.webp'
import image178 from '../assets/178.webp'
import image179 from '../assets/179.webp'
import image180 from '../assets/180.webp'
import image181 from '../assets/181.webp'
import image183 from '../assets/183.webp'
import image184 from '../assets/184.webp'
import image185 from '../assets/185.webp'
import image186 from '../assets/186.webp'
import image187 from '../assets/187.webp'
import image188 from '../assets/188.webp'
import image189 from '../assets/189.webp'
import image190 from '../assets/190.webp'

import image191 from '../assets/191.webp'
import image192 from '../assets/192.webp'
import image193 from '../assets/193.webp'
import image194 from '../assets/194.webp'
import image195 from '../assets/195.webp'
import image196 from '../assets/196.webp'
import image197 from '../assets/197.webp'
import image198 from '../assets/198.webp'
import image199 from '../assets/199.webp'
import image200 from '../assets/200.webp'
import image202 from '../assets/202.webp'
import image203 from '../assets/203.webp'
import image204 from '../assets/204.webp'
import image205 from '../assets/205.webp'
import image206 from '../assets/206.webp'

import image208 from '../assets/208.webp'
import image209 from '../assets/209.webp'
import image210 from '../assets/210.webp'
import image211 from '../assets/211.webp'
import image212 from '../assets/212.webp'
import image213 from '../assets/213.webp'
import image214 from '../assets/214.webp'
import image215 from '../assets/215.webp'
import image216 from '../assets/216.webp'
import image217 from '../assets/217.webp'
import image218 from '../assets/218.webp'
import image219 from '../assets/219.webp'
import image220 from '../assets/220.webp'
import image221 from '../assets/221.webp'
import image222 from '../assets/222.webp'
import image223 from '../assets/223.webp'
import image224 from '../assets/224.webp'
import image225 from '../assets/225.webp'
import image226 from '../assets/226.webp'
import image227 from '../assets/227.webp'
import image228 from '../assets/228.webp'

import image229 from '../assets/229.webp'
import image230 from '../assets/230.webp'
import image231 from '../assets/231.webp'
import image232 from '../assets/232.webp'
import image233 from '../assets/233.webp'
import image234 from '../assets/234.webp'
import image235 from '../assets/235.webp'
import image236 from '../assets/236.webp'
import image237 from '../assets/237.webp'
import image238 from '../assets/238.webp'
import image239 from '../assets/239.webp'
import image240 from '../assets/240.webp'
import image241 from '../assets/241.webp'
import image242 from '../assets/242.webp'
import image243 from '../assets/243.webp'
import image244 from '../assets/244.webp'


import image246 from '../assets/246.webp'
import image247 from '../assets/247.webp'
import image248 from '../assets/248.webp'
import image249 from '../assets/249.webp'
import image250 from '../assets/250.webp'
import image251 from '../assets/251.webp'


import image270 from '../assets/270.webp'
import image271 from '../assets/271.webp'

export const HotelDetail =[
    {
        id: 1,
        image: image2,
        title: 'Serene Escape Cottage 2 BHK',
        desc: 'Escape to our Deluxe Cottage, where tranquility meets luxury. Accommodating 6-9 guests per night, this haven offers optional meal services and breathtaking mountain views. Experience peace and serenity in our prime properties.',
        button: 'Book Now'
      },
      {
        id: 4,
        image: image51,
        title: 'Presidential Luxe Villa 3 BHK',
        desc: 'Experience luxury at our Presidential Luxe Villa, ideal for 8-12 guests. Enjoy optional meal services, an indoor pool, and expansive rooms.',
        button: 'Book Now'
      },
      {
        id: 3,
        image: image32,
        title: 'Royal Retreat Villa 3 BHK',
        desc: 'Unwind in royal luxury at our Villa, perfect for 8-12 guests. With optional meal services, youll enjoy a spectacular view of the mountains, making your stay truly unforgettable',
        button: 'Book Now'
      },
      {
        id: 8,
        image: image125,
        title: 'Penthouse Suite 3 BHK',
        desc: 'Experience The Height Of Luxury With Our Exclusive Penthouse Suite. This Expansive And Opulent Suite Is Located On The Top Floor, Offering Breathtaking City Views. With Multiple Bedrooms, A Private Rooftop Terrace, And Lavish Amenities, It Is The Epitome Of Sophistication And Indulgence.',
        button: 'Book Now'
      },
      {
        id: 13,
        image: image213,
        title: 'Executive Suite 5 BHK',
        desc: 'Designed For Discerning Guests, Our Sophisticated Executive Suite Offers A Seamless Blend Of Comfort And Productivity. With A Dedicated Workspace, High-Speed Internet, And Modern Amenities, It Provides The Perfect Environment For Work And Relaxation.',
        button: 'Book Now'
      },
      {
        id: 6,
        image: image95,
        title: 'Luxury Villa 3 BHK',
        desc: 'Experience the epitome of luxury with our stunning Luxury Villa. This spacious and exquisite villa features multiple bedrooms, a private pool, and breathtaking ocean views. It offers an unparalleled level of comfort and elegance for an unforgettable vacation.',
        button: 'Book Now'
      },
]

export const HotelRoomDetail = [
  {
    id: 1,
    name: "Serene Escape Cottage 2 BHK",
    slug: "serene-escape-cottage",
    type: "2bhk",
    price: {
      weekday: 10000,
      weekend: 12000,
    },
    images: [image2, image1, image3, image4, image5, image6, image7, image8, image9],
    capacity: 2,
    pets: false,
    breakfast: false,
    featured: false,
    description:
      "Escape to our Serene Escape Cottage, a perfect blend of tranquility and luxury. Ideal for 6-9 guests, enjoy optional meal services and breathtaking mountain views.",
    address: '📍A, Gold Valley Sector D, Gold Valley, Lonavala, Kune N.m., Maharashtra 410401',
    extras: [
      "24 Hours water supply",
      "Smart TV & Air Conditioners",
      "Geyser in All Bathrooms",
      "WiFi Facility",
      "Comfortable beds",
      "Music System",
      "Swimming Pool",
      "Extra beddings (chargeable)",
      "Food Facility (Optional)",
      "Open Balcony Space",
    ],
  },
  {
    id: 2,
    name: "Sona Homes Villa 2 BHK",
    slug: "Sona Homes Villa 2 BHK",
    type: "2bhk",
    price: {
      weekday: 10000,
      weekend: 12500,
    },
    images: [image11, image12, image13, image15, image16, image17, image18, image19, image23],
    capacity: 2,
    pets: false,
    breakfast: true,
    featured: true,
    description:
      "Indulge in luxury at our Opulent Haven Villa, accommodating 8-12 guests. Enjoy optional meal services and mesmerizing views of the Tungarli mountains and dam.",
    address: '📍QFHH+7C4, Dahivali, Maharashtra 410405',
    extras: [
      "24 Hours water supply",
      "Smart TV & Air Conditioners",
      "Geyser in All Bathrooms",
      "WiFi Facility",
      "Comfortable beds",
      "Music System",
      "Swimming Pool",
      "Extra beddings (chargeable)",
      "Food Facility (Optional)",
      "Open Balcony Space",
    ],
  },
  {
    id: 3,
    name: "Royal Retreat Villa 3 BHK",
    slug: "royal-retreat-villa",
    type: "3bhk",
    price: {
      weekday: 15000,
      weekend: 22500,
    },
    capacity: 3,
    pets: true,
    breakfast: true,
    featured: false,
    images: [image31, image32, image33, image34, image35, image36, image37, image38, image39, image40, image41, image42],
    description:
      "Unwind in royal luxury at our Royal Retreat Villa, perfect for 8-12 guests. Enjoy a spectacular view of the mountains and optional meal services.",
    address: '📍Tungarli, Lonavala, Maharashtra 410403',
    extras: [
      "24 Hours water supply",
      "Smart TV & Air Conditioners",
      "Geyser in All Bathrooms",
      "WiFi Facility",
      "Comfortable beds",
      "Music System",
      "Swimming Pool",
      "Extra beddings (chargeable)",
      "Food Facility (Optional)",
      "Open Balcony Space",
    ],
  },
  {
    id: 4,
    name: "Presidential Luxe Villa 3 BHK",
    slug: "presidential-luxe-villa",
    type: "3bhk",
    price: {
      weekday: 15000,
      weekend: 22500,
    },
    capacity: 3,
    pets: false,
    breakfast: true,
    featured: true,
    images: [image51, image52, image53, image54, image55, image56, image57, image58, image59, image60, image61, image62, image63, image64, image65],
    description:
      "Experience luxury at our Presidential Luxe Villa, ideal for 8-12 guests. Enjoy optional meal services, an indoor pool, and expansive rooms.",
    address: '📍Parshwapuram, Lonavala, Kune N.m., Maharashtra 410403',
    extras: [
      "24 Hours water supply",
      "Smart TV & Air Conditioners",
      "Geyser in All Bathrooms",
      "WiFi Facility",
      "Comfortable beds",
      "Music System",
      "Swimming Pool",
      "Extra beddings (chargeable)",
      "Food Facility (Optional)",
      "Open Balcony Space",
    ],
  },
  {
    id: 5,
    name: "Extravaganza Mansion 3 BHK",
    slug: "extravaganza-mansion",
    type: "3bhk",
    price: {
      weekday: 15000,
      weekend: 22500,
    },
    capacity: 3,
    pets: false,
    breakfast: false,
    featured: false,
    images: [
      image71, image72, image74, image75,
      image76, image77, image78, image79, image80,
      image81, image82, image83, image84, image85,
      image86, image87, image89, image90,
      image91
    ],
    description:
      "Experience extravagance at our Extravaganza Mansion, accommodating 8-12 guests. With optional meal services, exquisite 3 BHK villas, and an expansive lawn, it's perfect for events.",
    address: '📍Mannat villas,Tungarli,Lonavala',
    extras: [
      "24 Hours water supply",
      "Smart TV & Air Conditioners",
      "Geyser in All Bathrooms",
      "WiFi Facility",
      "Comfortable beds",
      "Music System",
      "Swimming Pool",
      "Extra beddings (chargeable)",
      "Food Facility (Optional)",
      "Open Balcony Space",
    ],
  },
  {
    id: 6,
    name: "Luxury Villa 3 BHK",
    slug: "luxury-villa",
    type: "3bhk",
    price: {
      weekday: 15000,
      weekend: 22500,
    },
    capacity: 3,
    pets: false,
    breakfast: true,
    featured: true,
    images: [image93, image94, image95, image96,
      image97, image98, image99, image100, image101,
      image102, image103, image104, image105],
    description:
      "Experience the epitome of luxury with our stunning Luxury Villa. This spacious and exquisite villa features multiple bedrooms, a private pool, and breathtaking ocean views. It offers an unparalleled level of comfort and elegance for an unforgettable vacation.",
    address: '📍Bliss Bunglow, opp.Treasure Island Resort, Devghar,Maharashtra 41240',
    extras: [
      "24 Hours water supply",
      "Smart TV & Air Conditioners",
      "Geyser in All Bathrooms",
      "WiFi Facility",
      "Comfortable beds",
      "Music System",
      "Swimming Pool",
      "Extra beddings (chargeable)",
      "Food Facility (Optional)",
      "Open Balcony Space",
    ],
  },
  {
    id: 7,
    name: "Enchanted Paradise Suite 3 BHK",
    slug: "honeymoon-suite",
    type: "3bhk",
    price: {
      weekday: 15000,
      weekend: 22500,
    },
    capacity: 3,
    pets: false,
    breakfast: true,
    featured: true,
    images: [image121, image109, image110, image111, image112, image113, image115, image116, image117, image118, image119, image120, image107, image122, image123, image124],
    description:
      "Indulge in a romantic escape with our exquisite villa. Designed for couples seeking privacy and luxury, this suite offers a beautifully decorated bedroom, a private balcony, and stunning views. Experience an unforgettable experience or a special anniversary in this enchanting retreat.",
    address: '📍Old Khandala road,Kurvande, Lonavala, Maharashtra',
    extras: [
      "24 Hours water supply",
      "Smart TV & Air Conditioners",
      "Geyser in All Bathrooms",
      "WiFi Facility",
      "Comfortable beds",
      "Music System",
      "Swimming Pool",
      "Extra beddings (chargeable)",
      "Food Facility (Optional)",
      "Open Balcony Space",
    ],
  },
  {
    id: 8,
    name: "Penthouse Suite 3 BHK",
    slug: "penthouse-suite",
    type: "3bhk",
    price: {
      weekday: 15000,
      weekend: 22500,
    },
    capacity: 3,
    pets: false,
    breakfast: true,
    featured: true,
    images: [image125, image127, image128, image129, image130, image131, image132, image133, image134, image135, image136, image137, image138, image139, image140, image141, image142, image143, image144, image145],
    description:
      "Experience the height of luxury with our exclusive Penthouse Suite. This expansive and opulent suite is located on the top floor, offering breathtaking city views. With multiple bedrooms, a private rooftop terrace, and lavish amenities, it is the epitome of sophistication and indulgence.",
    address: '📍Tungarli, Lonavala, Maharashtra',
    extras: [
      "24 Hours water supply",
      "Smart TV & Air Conditioners",
      "Geyser in All Bathrooms",
      "WiFi Facility",
      "Comfortable beds",
      "Music System",
      "Swimming Pool",
      "Extra beddings (chargeable)",
      "Food Facility (Optional)",
      "Open Balcony Space",
    ],
  },
  {
    id: 9,
    name: "Family Suite 4 BHK",
    slug: "family-suite",
    type: "4bhk",
    price: {
      weekday: 20000,
      weekend: 27500,
    },
    capacity: 4,
    pets: true,
    breakfast: true,
    featured: true,
    images: [image146, image147, image148, image149, image150, image151, image152, image153, image155, image156, image157, image159, image160],
    description:
      "Our spacious Family Suite is the perfect choice for families looking for comfort and convenience. With separate bedrooms, a cozy living area, and child-friendly amenities, it offers a home away from home experience. Create lasting memories with your loved ones in this welcoming suite.",
    address: '📍Stone Ridge Galli,Pahine,Trambakeshwar,Nashik Maharashtra 410405',
    extras: [
      "24 Hours water supply",
      "Smart TV & Air Conditioners",
      "Geyser in All Bathrooms",
      "WiFi Facility",
      "Comfortable beds",
      "Music System",
      "Swimming Pool",
      "Extra beddings (chargeable)",
      "Food Facility (Optional)",
      "Open Balcony Space",
    ],
  },
  {
    id: 10,
    name: "Executive Suite 4 BHK",
    slug: "executive-suite",
    type: "4bhk",
    price: {
      weekday: 20000,
      weekend: 27500,
    },
    capacity: 4,
    pets: false,
    breakfast: true,
    featured: false,
    images: [image161, image162, image163, image164, image165, image166, image167, image168, image169, image170, image171, image172],
    description:
      "Designed for discerning guests, our sophisticated Executive Suite offers a seamless blend of comfort and productivity. With a dedicated workspace, high-speed internet, and modern amenities, it provides the perfect environment for work and relaxation.",
    address: '📍QFM4+344, Karandoli, Maharashtra 410405',
    extras: [
      "Plush pillows and breathable bed linens",
      "24 Hours water supply",
      "Smart TV & Air Conditioners",
      "Geyser in All Bathrooms",
      "WiFi Facility",
      "Comfortable beds",
      "Music System",
      "Swimming Pool",
      "Extra beddings (chargeable)",
      "Food Facility (Optional)",
      "Open Balcony Space",
    ],
  },
  {
    id: 11,
    name: "Executive Suite 4 BHK",
    slug: "executive-suite",
    type: "4bhk",
    price: {
      weekday: 20000,
      weekend: 27500,
    },
    capacity: 4,
    pets: false,
    breakfast: true,
    featured: false,
    images: [image173, image174, image175, image176, image177, image178, image179, image180, image181, image183, image184, image185, image186, image187, image188, image189, image190],
    description:
      "Designed for discerning guests, our sophisticated Executive Suite offers a seamless blend of comfort and productivity. With a dedicated workspace, high-speed internet, and modern amenities, it provides the perfect environment for work and relaxation.",
    address: '📍INS Shivaji Back Gate, opp. Zomak Villas, Kurvande, Lonavala, Maharashtra 410402',
    extras: [
      "Plush pillows and breathable bed linens",
      "24 Hours water supply",
      "Smart TV & Air Conditioners",
      "Geyser in All Bathrooms",
      "WiFi Facility",
      "Comfortable beds",
      "Music System",
      "Swimming Pool",
      "Extra beddings (chargeable)",
      "Food Facility (Optional)",
      "Open Balcony Space",
    ],
  },
  {
    id: 12,
    name: "Executive Suite 4 BHK",
    slug: "Family",
    type: "4bhk",
    price: {
      weekday: 20000,
      weekend: 27500,
    },
    capacity: 4,
    pets: false,
    breakfast: true,
    featured: false,
    images: [image202, image192, image193, image194, image195, image196, image197, image198, image199, image200,image191, image203, image204, image205, image206],
    description:
      "Designed for discerning guests, our sophisticated Executive Suite offers a seamless blend of comfort and productivity. With a dedicated workspace, high-speed internet, and modern amenities, it provides the perfect environment for work and relaxation.",
    address: '📍At. Kurwande, near by Dukes nose, Lonavala, Maharashtra 410402',
    extras: [
      "Plush pillows and breathable bed linens",
      "24 Hours water supply",
      "Smart TV & Air Conditioners",
      "Geyser in All Bathrooms",
      "WiFi Facility",
      "Comfortable beds",
      "Music System",
      "Swimming Pool",
      "Extra beddings (chargeable)",
      "Food Facility (Optional)",
      "Open Balcony Space",
    ],
  },
  {
    id: 13,
    name: "Executive Suite 5 BHK",
    slug: "executive-suite",
    type: "5bhk",
    price: {
      weekday: 25000,
      weekend: 32500,
    },
    capacity: 5,
    pets: false,
    breakfast: true,
    featured: false,
    images: [image213, image209, image210, image211, image212, image208, image214, image215, image216, image217, image218, image219, image220, image221, image222, image223, image224, image225, image226, image227, image228],
    description:
      "Designed for discerning guests, our sophisticated Executive Suite offers a seamless blend of comfort and productivity. With a dedicated workspace, high-speed internet, and modern amenities, it provides the perfect environment for work and relaxation.",
    address: '📍Varsoli, Lonavala, Maharashtra 410405',
    extras: [
      "Plush pillows and breathable bed linens",
      "24 Hours water supply",
      "Smart TV & Air Conditioners",
      "Geyser in All Bathrooms",
      "WiFi Facility",
      "Comfortable beds",
      "Music System",
      "Swimming Pool",
      "Extra beddings (chargeable)",
      "Food Facility (Optional)",
      "Open Balcony Space",
    ],
  },
  {
    id: 14,
    name: "Executive Suite 8 BHK",
    slug: "executive-suite",
    type: "8bhk",
    price: {
      weekday: 40000,
      weekend: 55000,
    },
    capacity: 8,
    pets: false,
    breakfast: true,
    featured: false,
    images: [image229, image230, image231, image232, image233, image234, image235,
      image236, image237, image238, image239, image240, image241, image242,
      image243, image244],
    description:
      "Designed for discerning guests, our sophisticated Executive Suite offers a seamless blend of comfort and productivity. With a dedicated workspace, high-speed internet, and modern amenities, it provides the perfect environment for work and relaxation.",
    address: '📍INS Shivaji Back Gate, Opp. Zomak Villas, Kurvande, Lonavala, Maharashtra 410402',
    extras: [
      "Plush pillows and breathable bed linens",
      "24 Hours water supply",
      "Smart TV & Air Conditioners",
      "Geyser in All Bathrooms",
      "WiFi Facility",
      "Comfortable beds",
      "Music System",
      "Swimming Pool",
      "Extra beddings (chargeable)",
      "Food Facility (Optional)",
      "Open Balcony Space",
    ],
  },
  {
    id: 15,
    name: "Penthouse Suite 14 BHK",
    slug: "penthouse-suite",
    type: "14bhk",
    price: {
      weekday: 60000,
      weekend: 75000,
    },
    capacity: 14,
    pets: false,
    breakfast: true,
    featured: true,
    images: [image121, image109, image110, image111, image112, image113, image115, image116, image117, image118, image119, image120, image107, image122, image123, image124],
    description:
      "Experience the height of luxury with our exclusive Penthouse Suite. This expansive and opulent suite is located on the top floor, offering breathtaking city views. With multiple bedrooms, a private rooftop terrace, and lavish amenities, it is the epitome of sophistication and indulgence.",
    address: '📍At. Kurwande, Near By Dukes Nose, Lonavala, Maharashtra 410402',
    extras: [
      "24 Hours water supply",
      "Smart TV & Air Conditioners",
      "Geyser in All Bathrooms",
      "WiFi Facility",
      "Comfortable beds",
      "Music System",
      "Swimming Pool",
      "Extra beddings (chargeable)",
      "Food Facility (Optional)",
      "Open Balcony Space",
    ],
  },
  {
    id: 16,
    name: "Natures Nest service apartment",
    slug: "Natures Nest service apartment",
    type: "1bhk",
    price: {
      weekday: 60000,
      weekend: 75000,
    },
    capacity: 2,
    pets: false,
    breakfast: true,
    featured: true,
    images: [image246, image247, image248, image249, image250, image251],
    description:
      "Surrounded by greenery in the heart of lonavala, Natures Nest service apartment with all amenities.",
    address: '📍Lonavala, Maharashtra 410402',
    extras: [
      "24 Hours water supply",
      "Smart TV & Air Conditioners",
      "Geyser in All Bathrooms",
      "WiFi Facility",
      "Comfortable beds",
      "Music System",
      "Swimming Pool",
      "Extra beddings (chargeable)",
      "Food Facility (Optional)",
      "Open Balcony Space",
    ],
  },
  {
    id: 17,
    name: "Natures Nest 2BHK",
    slug: "villabation",
    type: "2bhk",
    price: {
      weekday: 60000,
      weekend: 75000,
    },
    capacity: 4-10,
    pets: false,
    breakfast: true,
    featured: true,
    images: [image270, image271, image248, image249, image250, image251],
    description:
      "Surrounded by greenery in the heart of lonavala, Natures Nest service apartment with all amenities.",
    address: '📍Lonavala, Maharashtra 410402',
    extras: [
      "24 Hours water supply",
      "Smart TV & Air Conditioners",
      "Geyser in All Bathrooms",
      "WiFi Facility",
      "Comfortable beds",
      "Music System",
      "Swimming Pool",
      "Extra beddings (chargeable)",
      "Food Facility (Optional)",
      "Open Balcony Space",
    ],
  },
];