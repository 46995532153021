import React from "react";

const PrivacyPolicy = () => {
  return (
    <section className="py-10 px-4">
      <div className="mx-auto max-w-7xl">
        <h1 className="text-2xl font-bold mb-6">Privacy Policy</h1>
        <p className="mb-4">
          At Coolstays@365, we are committed to protecting your privacy. This
          privacy policy outlines how we store and manage your data.
        </p>
        <p className="mb-4">
          <strong>Data Storage:</strong> The data we collect during the villa
          booking process is stored until the booking is complete and the
          checkout process is finalized. After the checkout, your data is no
          longer stored in our system.
        </p>
        <p>
          For any questions or concerns regarding your privacy, please contact
          us at <a href="mailto:coolstays365@gmail.com">coolstays365@gmail.com</a>.
        </p>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
