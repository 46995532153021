import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { db, storage } from "./firebase";
import { collection, addDoc, getDocs, query, where } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const BookingPage = () => {
  const location = useLocation();
  const history = useNavigate();
  const { id: villaId, name, price, images } = location.state || {};

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [formData, setFormData] = useState({
    guestName: "",
    phoneNumber: "",
    email: "", 
    guests: 1,
    aadhaarCard: null,
    guestNames: [""],
  });
  const [errors, setErrors] = useState({});
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [bookedDates, setBookedDates] = useState([]);
  const [bookingData, setBookingData] = useState(null);
  
  useEffect(() => {
    if (!villaId) {
      console.error("Villa ID is undefined");
      return;
    }

    const fetchBookedDates = async () => {
      const bookingRef = collection(db, `villas/${villaId}/bookings`);
      const querySnapshot = await getDocs(bookingRef);
      let dates = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const range = getDatesInRange(new Date(data.startDate), new Date(data.endDate));
        dates = dates.concat(range);
      });
      setBookedDates(dates);
    };

    fetchBookedDates();
  }, [villaId]);

  useEffect(() => {
    if (startDate && endDate) {
      const diffTime = Math.abs(endDate - startDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      let total = 0;
      for (let i = 0; i < diffDays; i++) {
        const currentDate = new Date(startDate);
        currentDate.setDate(currentDate.getDate() + i);
        const dayOfWeek = currentDate.getDay();
        total += dayOfWeek === 5 || dayOfWeek === 6 || dayOfWeek === 0 ? price.weekend : price.weekday;
      }
      setTotalPrice(total);
    }
  }, [startDate, endDate, price]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (e) => {
    setFormData((prevData) => ({ ...prevData, aadhaarCard: e.target.files[0] }));
  };

  const handleGuestsChange = (operation, value) => {
    setFormData((prevData) => {
      const guests = operation === "manual" ? value : operation === "increase" ? prevData.guests + 1 : prevData.guests - 1;
      if (guests > 0) {
        const guestNames = [...Array(guests)].map((_, index) => prevData.guestNames[index] || "");
        return { ...prevData, guests, guestNames };
      }
      return prevData;
    });
  };

  const handleGuestNameChange = (index, value) => {
    const updatedGuestNames = [...formData.guestNames];
    updatedGuestNames[index] = value;
    setFormData((prevData) => ({ ...prevData, guestNames: updatedGuestNames }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.guestName.trim()) newErrors.guestName = "Your name is required";
    if (!formData.phoneNumber.trim()) newErrors.phoneNumber = "Phone number is required";
    if (!formData.email.trim()) newErrors.email = "Email is required";
    if (formData.guests <= 0) newErrors.guests = "Number of guests is required";
    if (!formData.aadhaarCard) newErrors.aadhaarCard = "Aadhaar card is required";
    formData.guestNames.forEach((guestName, index) => {
      if (!guestName.trim()) newErrors[`guestName${index}`] = `Guest ${index + 1} name is required`;
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const isBookingCollision = async (newBooking) => {
    const q = query(
      collection(db, `villas/${villaId}/bookings`),
      where("startDate", "<=", newBooking.endDate)
    );
    const querySnapshot = await getDocs(q);
    let collision = false;
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      if (new Date(data.endDate) > newBooking.startDate) {
        collision = true;
      }
    });
    return collision;
  };

  


  const handlePaymentSuccess = async (razorpayResponse, newBooking) => {
    try {
      const { razorpay_payment_id, razorpay_order_id, razorpay_signature } = razorpayResponse;
  
      // Verify payment via your backend
      const verificationResponse = await axios.post(`https://backend-sooty-mu.vercel.app/api/verify-capture`, {
        razorpay_order_id,       // Use correct field name
        razorpay_payment_id,     // Use correct field name
        razorpay_signature,      // Use correct field name
        bookingData: newBooking
      });
  
  
      if (verificationResponse.data.verified) {
        // Proceed with booking update
        console.log("Payment verified, proceeding with booking.");
        
        const aadhaarCardRef = ref(storage, `aadhaarCards/${formData.aadhaarCard.name}`);
        await uploadBytes(aadhaarCardRef, formData.aadhaarCard);
        const aadhaarCardUrl = await getDownloadURL(aadhaarCardRef);
        newBooking.aadhaarCard = aadhaarCardUrl;
  
        await addDoc(collection(db, `villas/${villaId}/bookings`), newBooking);
        setShowErrorMessage(false);
        setBookingData(newBooking);
        history("/success");
  
        sendNotifications(newBooking);
      } else {
        alert('Payment verification failed. Please try again.');
      }
    } catch (error) {
      console.error("There was an error during the booking process:", error);
      alert('Failed to confirm booking. Please contact support.');
    }
  };
  
  
  const sendNotifications = async (newBooking) => {
    // Send WhatsApp message to admin
    try {
      const adminResponse = await axios.post('https://backend-sooty-mu.vercel.app/api/send-whatsapp', newBooking);
      console.log('WhatsApp message sent to admin:', adminResponse.data);
    } catch (error) {
      console.error('Error sending WhatsApp message to admin:', error.response ? error.response.data : error.message);
    }
  
    // Send WhatsApp message to user
    try {
      const userResponse = await axios.post('https://backend-sooty-mu.vercel.app/api/send-user-whatsapp', {
        phoneNumber: formData.phoneNumber,
        message: `Your booking for ${name} from ${startDate.toISOString().split('T')[0]} to ${endDate.toISOString().split('T')[0]} is confirmed. Total Price: ₹${totalPrice}.`
      });
      console.log('WhatsApp message sent to user:', userResponse.data);
    } catch (error) {
      console.error('Error sending WhatsApp message to user:', error.response ? error.response.data : error.message);
    }
  };
  

  const loadRazorpay = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(true);  // Script loaded successfully
      };
      script.onerror = () => {
        resolve(false); // Script failed to load
      };
      document.body.appendChild(script);
    });
  };
  
  const createRazorpayOrder = async (amount) => {
    try {
      const response = await axios.post('https://backend-sooty-mu.vercel.app/api/create-order', { amount: amount });
      return response.data; // Assuming this returns the order details including order ID
    } catch (error) {
      console.error("Error creating Razorpay order:", error);
      return null;
    }
  };
  
  const handlePayment = async (newBooking) => {
    const res = await loadRazorpay('https://checkout.razorpay.com/v1/checkout.js');
    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?');
      return;
    }
    const order = await createRazorpayOrder(newBooking.totalPrice);
    if (!order) {
        alert('Failed to create order. Please try again.');
        return;
    }
    var options = {
      "key": "rzp_live_1qPPpw7rcymPrY", // Enter the Key ID generated from the Razorpay Dashboard
      "amount": totalPrice * 100, // Amount in smallest currency unit
      "currency": "INR",
      "name": "CoolStays@365",
      "order_id": order.id,
      "description": "Coolstay@365 pvt ltd at your service",
      "image": "https://i.ibb.co/nC9M130/logo.png",
      "handler": function (response) {
        handlePaymentSuccess(response, newBooking);
      },
      "prefill": {
        "name": formData.guestName,
        "email": formData.email,
        "villaName": newBooking.name,
        "contact": formData.phoneNumber,
        "checkInDate": newBooking.startDate, // Add check-in date
        "checkOutDate": newBooking.endDate // Add check-out date
      },
      "theme": {
        "color": "#54f3c2"
      }
    };
  
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };
  
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const newBooking = {
        guestName: formData.guestName,
        phoneNumber: formData.phoneNumber,
        email: formData.email,
        guests: formData.guests,
        guestNames: formData.guestNames,
        aadhaarCard: formData.aadhaarCard,
        name,
        totalPrice,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      };

      if (await isBookingCollision(newBooking)) {
        setShowErrorMessage(true);
      } else {
        setBookingData(newBooking);
        document.getElementById('paymentModal').classList.remove('hidden');
      }
    } else {
      setShowErrorMessage(true);
    }
  };

  const getDatesInRange = (startDate, endDate) => {
    const dates = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  };
  

  const renderDayContents = (day, date) => {
    return (
      <div className="flex flex-col items-center">
        <span>{day}</span>
      </div>
    );
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-beige p-4">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-5xl flex flex-col md:flex-row">
        <div className="md:w-2/3 p-4 flex flex-col">
          {images && (
            <img
              src={Array.isArray(images) ? images[0] : images}
              alt={name}
              className="w-full h-64 object-cover rounded-lg mb-4 shadow-md"
            />
          )}
          <h1 className="text-3xl font-bold mb-4 text-dark-brown">{name}</h1>
          <p className="text-lg mb-4 text-dark-brown">
            Price per night: ₹{price?.weekday} (Weekday), ₹{price?.weekend} (Weekend)
            <p>Weekdays: Monday to Thursday</p>
            <p>Weekends: Friday to Sunday</p>
          </p>
          <div className="mb-4">
            <label className="block text-sm font-medium text-dark-brown">Booking Dates:</label>
            <DatePicker
              selected={startDate}
              onChange={(dates) => {
                const [start, end] = dates;

                // Validate end date
                if (end && bookedDates.some((bookedDate) => {
                  return bookedDate.getTime() === end.getTime() || bookedDate.getTime() < end.getTime();
                })) {
                  // If end date overlaps or is before booked start date, set end to next day
                  setEndDate(new Date(end.getTime() + (24 * 60 * 60 * 1000)));
                } else {
                  // If end date is valid, update both start and end dates
                  setStartDate(start);
                  setEndDate(end);
                }
              }}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              excludeDates={bookedDates}
              minDate={new Date()}
              inline
              renderDayContents={renderDayContents}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-dark-brown focus:ring-dark-brown sm:text-sm"
              dayClassName={(date) => {
                const isWeekend = date.getDay() === 0 || date.getDay() === 6;
                return isWeekend ? "weekend" : "weekday";
              }}
            />
          </div>
          <p className="text-xl font-bold text-dark-brown">Total Price: ₹{totalPrice}</p>
        </div>
        <div className="md:w-1/3 p-4">
          <h2 className="text-2xl font-bold mb-4 text-dark-brown">Guest Details</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="guestName" className="block text-sm font-medium text-dark-brown">
                Your Name
              </label>
              <input
                type="text"
                id="guestName"
                name="guestName"
                value={formData.guestName}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-dark-brown focus:ring-dark-brown sm:text-sm"
              />
              {errors.guestName && <p className="text-red-600 text-sm mt-1">{errors.guestName}</p>}
            </div>
            <div className="mb-4">
              <label htmlFor="phoneNumber" className="block text-sm font-medium text-dark-brown">
                Phone Number
              </label>
              <input
                type="text"
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-dark-brown focus:ring-dark-brown sm:text-sm"
              />
              {errors.phoneNumber && <p className="text-red-600 text-sm mt-1">{errors.phoneNumber}</p>}
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block text-sm font-medium text-dark-brown">
                Email 
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className={errors.email ? "error" : ""}
              />
              {errors.email && <span className="error-message">{errors.email}</span>}
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-dark-brown">Number of Guests</label>
              <div className="flex items-center">
                <button
                  type="button"
                  className="px-2 py-1 bg-gray-300 rounded-l focus:outline-none"
                  onClick={() => handleGuestsChange("decrease")}
                >
                  -
                </button>
                <input
                  type="number"
                  id="guests"
                  name="guests"
                  min="1"
                  value={formData.guests}
                  onChange={(e) => handleGuestsChange("manual", parseInt(e.target.value, 10))}
                  className="w-12 text-center border-t border-b border-gray-300"
                />
                <button
                  type="button"
                  className="px-2 py-1 bg-gray-300 rounded-r focus:outline-none"
                  onClick={() => handleGuestsChange("increase")}
                >
                  +
                </button>
              </div>
              {errors.guests && <p className="text-red-600 text-sm mt-1">{errors.guests}</p>}
            </div>
            {formData.guestNames.map((guest, index) => (
              <div className="mb-4" key={index}>
                <label htmlFor={`guestName${index}`} className="block text-sm font-medium text-dark-brown">
                  Guest {index + 1} Name
                </label>
                <input
                  type="text"
                  id={`guestName${index}`}
                  name={`guestName${index}`}
                  value={guest}
                  onChange={(e) => handleGuestNameChange(index, e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-dark-brown focus:ring-dark-brown sm:text-sm"
                />
                {errors[`guestName${index}`] && <p className="text-red-600 text-sm mt-1">{errors[`guestName${index}`]}</p>}
              </div>
            ))}
            <div className="mb-4">
              <label htmlFor="aadhaarCard" className="block text-sm font-medium text-dark-brown">
                Aadhaar Card (PDF)
              </label>
              <input
                type="file"
                id="aadhaarCard"
                name="aadhaarCard"
                accept="application/pdf"
                onChange={handleFileChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-dark-brown focus:ring-dark-brown sm:text-sm"
              />
              {errors.aadhaarCard && <p className="text-red-600 text-sm mt-1">{errors.aadhaarCard}</p>}
            </div>
            {showErrorMessage && <p className="text-red-600 text-sm mt-2">Please fill in all fields or dates overlap with existing bookings</p>}
            <button
              type="submit"
              className="w-full mt-4 px-6 py-3 bg-blue-600 text-white rounded-full flex items-center justify-center hover:bg-blue-700 transition duration-300"
            >
              <i className="fas fa-calendar-check mr-2"></i> Confirm Booking
            </button>
          </form>
        </div>
      </div>

      {/* Payment Modal */}
      <div id="paymentModal" className="hidden fixed z-50 inset-0 overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                  <i className="fas fa-credit-card text-blue-600"></i>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                    Payment
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Please complete the payment to confirm your booking.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() => handlePayment(bookingData)}
              >
                Proceed to Pay
              </button>
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:w-auto sm:text-sm"
                onClick={() => document.getElementById('paymentModal').classList.add('hidden')}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingPage;
