import React from 'react';
import { useNavigate } from 'react-router-dom';
import { HotelRoomDetail } from '../Detail/HotelDetail';

const VillasList = () => {
  const navigate = useNavigate();

  const handleBookingClick = (villa) => {
    navigate('/booking', {
      state: { 
        id: villa.id,
        name: villa.name,
        price: villa.price,
        images: villa.images
      }
    });
  };

  return (
    <div>
      {HotelRoomDetail.map(villa => (
        <div key={villa.id}>
          <h2>{villa.name}</h2>
          <p>{villa.description}</p>
          <button onClick={() => handleBookingClick(villa)}>Book Now</button>
        </div>
      ))}
    </div>
  );
};

export default VillasList;
