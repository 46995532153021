import React, { useState, useEffect } from 'react';
import image from '../assets/36a9f6c9-a61f-4b86-b684-3d47a58791b5.JPG';

const WhatsAppIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    className="w-8 h-8 fill-current text-white animate-pulse"
  >
    <path d="M16 0C7.16 0 0 7.16 0 16c0 2.85.75 5.56 2.09 7.97L.04 32l8.29-2.03A15.91 15.91 0 0016 32c8.84 0 16-7.16 16-16S24.84 0 16 0zm0 29.5c-2.7 0-5.32-.75-7.58-2.15l-.54-.34-4.93 1.2 1.2-4.83-.35-.55C2.25 21.32 1.5 18.7 1.5 16 1.5 8.56 8.56 1.5 16 1.5S30.5 8.56 30.5 16 23.44 29.5 16 29.5zm7.07-10.87c-.34-.17-2.03-1-2.34-1.1-.3-.1-.52-.17-.74.18-.21.34-.86 1.1-1.06 1.32-.2.22-.39.26-.73.1-.34-.17-1.45-.53-2.77-1.68-1.02-.91-1.71-2.03-1.91-2.37-.2-.34-.02-.52.15-.68.16-.17.34-.39.52-.59.18-.2.23-.34.34-.56.11-.22.06-.42-.03-.6-.08-.17-.74-1.78-1.01-2.45-.27-.67-.54-.57-.74-.57h-.64c-.2 0-.52.08-.8.38s-1.05 1.04-1.05 2.55 1.08 2.96 1.23 3.17c.15.2 2.13 3.26 5.15 4.57.72.31 1.28.5 1.71.64.72.23 1.38.2 1.89.12.57-.08 1.75-.72 2-1.41.25-.69.25-1.28.17-1.41-.08-.13-.29-.2-.6-.36z" />
  </svg>
);

export function HeroSection() {
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.whatsapp-button')) {
        setShowPrompt(false);
      }
    };
    
    document.addEventListener('click', handleClickOutside);
    
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleWhatsAppClick = () => {
    const phoneNumber = "917909530053"; // Replace with your phone number in international format, e.g., "1234567890"
    const message = "Hello, I'm interested in booking a villa in Lonavla."; // Replace with your default message
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.location.href = url;
  };

  return (
    <div className="relative w-full pb-8">
      <div className="mx-auto max-w-7xl lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8">
        <div className="flex flex-col justify-center px-4 py-2 md:py-4 lg:col-span-7 lg:gap-x-6 lg:px-6 lg:py-6 xl:col-span-6">
          <div className="mt-4 flex text-white max-w-max items-center space-x-2 rounded-full bg-[#0B8185] p-1">
            <div className="rounded-full bg-[#7c2b10d0] p-1 px-2">
              <p className="text-sm font-medium">Luxurious</p>
            </div>
            <p className="text-sm font-medium">Find Your Villa</p>
          </div>
          <h1 className="mt-4 text-3xl font-bold tracking-tight md:text-4xl lg:text-6xl">
            Discover amazing Villa's in Lonavala and Enjoy a <span className="text-[#0B8185] capitalize">memorable stay</span>
          </h1>
          <p className="mt-4 text-lg">
          Experience Our Luxurious, Elegant and Unique Villas with Breathtaking Mountain views of Lonavala!
          </p>
        </div>
        <div className="relative lg:col-span-5 lg:-mr-8 xl:col-span-6">
          <img
            className="aspect-[3/2] bg-gray-50 rounded object-cover lg:aspect-[4/3] lg:h-[700px] xl:aspect-[16/9]"
            src={image}
            alt="Villa in Lonavla"
          />
        </div>
      </div>
      <div
        onMouseEnter={() => setShowPrompt(true)}
        onMouseLeave={() => setShowPrompt(false)}
        className="whatsapp-button fixed bottom-4 right-4 flex items-center space-x-2 p-3 bg-[#25D366] rounded-full shadow-lg hover:bg-[#1ebe57] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#1ebe57] z-50 cursor-pointer"
        onClick={handleWhatsAppClick}
      >
        <WhatsAppIcon />
        {showPrompt && (
          <span className="text-white text-sm font-medium">Want to book a offline or have a special request?</span>
        )}
      </div>
      <style jsx>{`
        @keyframes pulse {
          0% {
            transform: scale(1);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
          }
          70% {
            transform: scale(1.1);
            box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
          }
          100% {
            transform: scale(1);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
          }
        }

        .animate-pulse {
          animation: pulse 2s infinite;
        }
      `}</style>
    </div>
  );
}
