import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import WrapperContainer from "../components/WrapperContainer";
import { HotelRoomDetail } from "../Detail/HotelDetail";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useAuth } from '../contextApi/AuthContext';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';

const SingleHotelView = () => {
  const { id } = useParams();
  const [hotelDetail, setHotelDetail] = useState(null);
  const navigate = useNavigate();
  const { user, auth, setUser } = useAuth();

  useEffect(() => {
    const arrayDetail = [...HotelRoomDetail];
    const hotelDetailFilter = arrayDetail.find(detail => detail.id === +id);
    setHotelDetail(hotelDetailFilter);
  }, [id]);

  if (!hotelDetail) {
    return <div>Loading...</div>; // or a loading indicator
  }

  const { images, capacity, name, description, type, price, extras, address } = hotelDetail;

  // Determine if today is a weekday or weekend
  const today = new Date();
  const isWeekend = today.getDay() === 5 || today.getDay() === 6; // 5: Friday, 6: Saturday

  const handleBookNow = () => {
    if (!user) {
      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider)
        .then((result) => setUser(result.user))
        .then(() => navigate('/booking', { state: { id, name, price, images } }))
        .catch((error) => console.error("Authentication failed:", error));
    } else {
      navigate('/booking', { state: { id, name, price, images } });
    }
  };

  return (
    <div
      className="bg-cover bg-center min-h-screen relative pt-16 pb-16"
      style={{ backgroundImage: `url(${images[0]})` }}
    >
      <WrapperContainer>
        <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-transparent"></div>
        <div className="relative w-full h-full flex flex-col justify-center">
          <div className="container mx-auto text-[#fcf2de] capitalize px-4 md:px-8">
            <p className="text-center p-4 py-8 text-2xl md:text-4xl lg:text-6xl font-bold leading-tight">
              Experience unparalleled luxury and comfort
              <br />
              in our exquisite villas
              <br />
              nestled in breathtaking surroundings
            </p>
            <div className="flex flex-col md:flex-row justify-between items-center py-8">
              <div className="w-full md:w-1/2 p-4">
                <Carousel>
                  {images.map((img, index) => (
                    <div key={index}>
                      <img className="rounded" src={img} alt={name} />
                    </div>
                  ))}
                </Carousel>
              </div>
              <div className="p-4 w-full md:w-1/2">
                <span className="block text-lg md:text-xl">Rooms: {capacity}</span>
                <h2 className="text-3xl md:text-5xl lg:text-7xl font-bold">{name}</h2>
                <span className="block my-2 md:my-4 text-sm md:text-base">{description}</span>
                <p className="text-base md:text-lg">{type}</p>
                <p className="text-white text-2xl md:text-3xl lg:text-4xl py-4">
                  Price today: ₹{isWeekend ? price?.weekend : price?.weekday}
                </p>
                <p className="text-white text-lg md:text-xl">{address}</p>
                <ul className="pl-4 md:pl-6 list-disc">
                  {extras.map((detail, i) => (
                    <li className="text-sm md:text-base" key={i}>{detail}</li>
                  ))}
                </ul>
                <button
                  onClick={handleBookNow}
                  className="mt-4 px-6 py-3 bg-blue-600 text-white rounded-full flex items-center hover:bg-blue-700 transition duration-300"
                >
                  <i className="fas fa-calendar-check mr-2"></i> Book Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </WrapperContainer>
      <WhatsAppWidget
        phoneNumber="917909530053" // Replace with your phone number
        companyName="Coolstays365" // Replace with your company name
        message="Hello! How can we help you today?" // Customize the default message
      />
    </div>
  );
};

export default SingleHotelView;
