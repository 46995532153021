import React, { useContext } from 'react';
import { HotelRoomDetail } from '../Detail/HotelDetail';
import { Card } from '../components/Slider';
import WrapperContainer from '../components/WrapperContainer';
import SearchRooms from '../components/SearchRooms';
import { Link } from 'react-router-dom';
import Contex from '../contextApi/Contex'; // Ensure correct import
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';

const CategoryPage = () => {
  const { Room, Guest, PriceRange } = useContext(Contex);

  let detail = HotelRoomDetail;
  if (Room !== '' && Room !== 'All') {
    detail = HotelRoomDetail.filter(item => item.type === Room);
  }
  if (Guest !== '') {
    detail = detail.filter(item => item.capacity === Guest);
  }
  if (PriceRange !== '') {
    detail = detail.filter(item => item.price === PriceRange);
  }

  return (
    <div className="bg-[#d27548]">
      <WrapperContainer>
        <SearchRooms />
        <div id="showcase-Section" className="flex flex-wrap flex-col md:flex-row justify-between items-center pt-8">
          {detail.map(detail => (
            <Link to={`/SingleHotelView/${detail.id}`} key={detail.id}>
              <Card detail={detail} />
            </Link>
          ))}
        </div>
      </WrapperContainer>
      <WhatsAppWidget
        phoneNumber="917909530053" // Replace with your phone number
        companyName="Coolstays365" // Replace with your company name
        message="Hello! How can we help you today?" // Customize the default message
      />
    </div>
  );
};

export default CategoryPage;
